<template>
  <div class="sidebar">
    <el-menu
      :default-active="onRoute"
      class="sidebar-el-menu"
      :collapse="collapse"
      :background-color="subjectColor"
      text-color="#17191A"
      active-text-color="#51CBCD"
      router
      unique-opened
    >
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-submenu :index="item.index"
                      :key="item.index"
                      v-if='item.member'>
            <template slot="title" >
              <!-- 预留字体图标 -->
              <svg
                class="icon"
                aria-hidden="true"
                style="margin-right: 12px; font-size: 18px"
              >
                <use :xlink:href="item.icon"></use>
              </svg>
              <span slot="title" class="title">{{ item.title }} </span>
            </template>
            <template v-for="subItem in item.subs">
              <template v-if="subItem.root">
                <el-submenu
                  v-if="subItem.subs && isMain"
                  :index="subItem.index"
                  :key="subItem.index"
                  class="title"
                >
                  <template slot="title" class="title">{{
                    subItem.title
                  }}</template>
                  <el-menu-item
                    v-for="(threeItem, i) in subItem.subs"
                    :key="i"
                    :index="threeItem.index"
                    class="title"
                    >{{ threeItem.title }}</el-menu-item
                  >
                </el-submenu>
                <el-menu-item
                  v-else-if="isMain || subItem.administrator"
                  :index="subItem.index"
                  :key="subItem.index"
                  class="title pl50"
                  >{{ subItem.title }}</el-menu-item
                >
              </template>
              <template v-else>
                <el-submenu
                  v-if="subItem.subs && subItem.memberRoot"
                  :index="subItem.index"
                  :key="subItem.index"
                  class="title pl10"
                >
                  <template slot="title" class="title">{{
                    subItem.title
                  }}</template>
                  <el-menu-item
                    v-for="(threeItem, i) in subItem.subs"
                    :key="i"
                    :index="threeItem.index"
                    class="title "
                    >{{ threeItem.title }}</el-menu-item
                  >
                </el-submenu>
                <el-menu-item
                  v-else
                  :index="subItem.index"
                  :key="subItem.index"
                  class="title pl50"
                  :class="!subItem.memberRoot ? 'memberRoot' : '' "
                  >{{ subItem.title }}</el-menu-item
                >
              </template>

            </template>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index" class="title" v-if='item.member && !item.isAgent'>
            <!-- 预留字体图标 -->
            <svg
              class="icon"
              aria-hidden="true"
              style="margin-right: 7px; font-size: 18px"
            >
              <use :xlink:href="item.icon"></use>
            </svg>
            <span slot="title" class="title">
              {{ item.title }}</span>
          </el-menu-item>
          <el-menu-item :index="item.index" :key="item.index" class="title" v-else-if='info.isAgent == 1 && info.roleId'>
            <!-- 代理企业 -->
            <svg
              class="icon"
              aria-hidden="true"
              style="margin-right: 7px; font-size: 18px"
            >
              <use :xlink:href="item.icon"></use>
            </svg>
            <span slot="title" class="title">
              {{ item.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import bus from "../../service/bus";
import { mapState } from 'vuex';
export default {
  name: "commonSidebar",
  data() {
    return {
      collapse: false,
      isMain:false,
      member:false,
      info:'',
      items: [
        {
          icon: "#icon-dashboard",
          index: "dashboard",
          title: "工作台",
          member:true
        },
        {
          icon: "#icon-team",
          index: "customer",
          title: "客户管理",
          member:true,
          type:'customer',
          subs: [
            {
              index: "customerInput",
              title: "客户录入",
              memberRoot:true,
            },
            {
              index: "customerManagement",
              title: "客户管理",
              memberRoot:true,
            },
            {
              index: "totalCustomer",
              title: "总客户池",
              memberRoot:true,
              id:'01',
            },
            // {
            //   index: "visitCustomers",
            //   title: "访问客户",
            // },
          ],
        },
        {
          icon: "#icon-bank",
          index: "3",
          title: "企业管理",
          member:true,
          id:'02',
          subs: [
            {
              index: "corporateInformation",
              title: "企业信息",
              memberRoot:true,
              id: '020'
            },
            {
              index: "enterpriseMember",
              title: "企业成员",
              id: '021',
              memberRoot:true,
            },
             {
              index: "performanceAssessment",
              id: '024',
              memberRoot:true,
              title: "成员考核",
            },
            {
              index: "advancedForm",
              title: "高级表单",
              memberRoot:true,
              id: '022'
            },
           
            {
              index: "advertisingManagement",
              title: "广告图管理",
              memberRoot:true,
              id: '023'
            },
          ],
        },
        {
          icon: "#icon-file-text",
          index: "orderManagement",
          title: "订单管理",
          member:true,
          id:'03',
        },
        {
          icon: "#icon-shopping",
          index: "merchandiseManagement",
          title: "商品管理",
          member:true,
          id:'04',
          subs: [
            {
              index: "management",
              title: "商品管理",
              memberRoot:true,
              id: '040'
            },
            {
              index: "appointmentManagement",
              title: "预约管理",
              memberRoot:true,
              id: '041'
            },
            // {
            //   index: "freightManagement",
            //   title: "运费管理",
            // },
          ],
        },
        {
          icon: "#icon-gift",
          index: "marketingActivities",
          title: "营销活动",
          member:true,
          id:'05',
          subs: [
            {
              index: "distribution",
              title: "分销",
              id: '050',
              memberRoot:true,
              subs: [
                {
                  index: "distributionStatistics",
                  title: "分销统计",
                  memberRoot:true,
                },
                {
                  index: "personnelApplication",
                  title: "人员申请",
                  memberRoot:true,
                },
                {
                  index: "distributionLevel",
                  title: "分销等级",
                  memberRoot:true,
                },
                {
                  index: "distributionOrder",
                  title: "分销订单",
                  memberRoot:true,
                },
                {
                  index: "accountTransfer",
                  title: "到账流水",
                  memberRoot:true,
                },
              ],
            },
            {
              index: "joinGroup",
              title: "拼团",
              memberRoot:true,
              id: '051',
              subs: [
                {
                  index: "productList",
                  title: "拼团商品列表",
                  memberRoot:true,
                },
                {
                  index: "listParticipants",
                  title: "参团列表",
                  memberRoot:true,
                },
              ],
            },
            {
              index: "spike",
              title: "秒杀",
              memberRoot:true,
              id: '052',
              subs: [
                {
                  index: "spikeList",
                  title: "秒杀商品列表",
                  memberRoot:true,
                },
              ],
            },
            {
              index: "couponManagement",
              title: "优惠券管理",
              id: '053',
              memberRoot:true,
              subs: [
                {
                  index: "couponList",
                  title: "优惠券列表",
                  memberRoot:true,
                },
              ],
            },
          ],
        },
        {
          icon: "#icon-mobile",
          index: "TurnToIntroduce",
          title: "转介绍管理",
          id:'012',
          member:true,
          subs: [
            {
              index: "partnerList",
              title: "合作伙伴列表",
              memberRoot:true,
              id: '0120' 
            },
            {
              index: "rulesSetting",
              title: "规则设置",
              memberRoot:true,
              id: '0121' 
            },
            {
              index: "withdrawalList",
              title: "提现列表",
              memberRoot:true,
              id: '0122' 
            },
          ],
        },
        {
          icon: "#icon-creditcard",
          index: "financialManagement",
          title: "财务管理",
          member:true,
          id:'06',
          subs: [
            {
              index: "financialStatistics",
              title: "财务统计",
              id: '060',
              memberRoot:true,
            },
            {
              index: "commissionManagement",
              title: "佣金管理",
              id: '061',
              memberRoot:true,
            },
            {
              index: "accountManagement",
              title: "账户管理",
              root:true,
              memberRoot:true,
            },
          ],
        },
        {
          icon: "#icon-cluster",
          index: "agentManage",
          title: "代理企业",
          member:true,
          isAgent:true,
        },
        {
          icon: "#icon-solution",
          index: "articleManagement",
          title: "获客文章管理",
          member:true,
          id:'07',
          subs: [
            {
              index: "customerArticles",
              title: "获客文章",
              memberRoot:true,
              id: '070'
            },
            {
              index: "employeeData",
              title: "员工数据",
              memberRoot:true,
              id: '071'
            },
            {
              index: "visitorData",
              title: "访客数据",
              memberRoot:true,
              id: '072'
            },
          ],
        },
        {
          icon: "#icon-video",
          index: "videoManage",
          title: "短视频管理",
          member:true,
          id:'08',
        },
        {
          icon: "#icon-key",
          index: "addedServices",
          title: "增值服务",
          member:true,
          id:'09',
          subs: [
            {
              index: "myNeeds",
              title: "我的需求",
              memberRoot:true,
            },
            {
              index: "demandCheck",
              title: "需求审核",
              root:true,
              administrator:false,
              memberRoot:true,
              id: '090'
            },
            {
              index: "demandContactsCheck",
              title: "人脉集市审核",
              root:true,
              administrator:false,
              memberRoot:true,
              id: '092'
            },
            {
              index: "demandProtuctsCheck",
              title: "产品中心审核",
              root:true,
              administrator:false,
              memberRoot:true,
              id: '091'
            },
            {
              index: "demandPublishSetting",
              title: "发布设置",
              memberRoot:true,
              administrator:false,
              root:true,
              id: '093'
            },
            {
              index: "demandReport",
              title: "举报处理",
              memberRoot:true,
              administrator:false,
              root:true,
              id: '090'
            },
            {
              index: "demandCustomize",
              title: "自定义设置",
              memberRoot:true,
              administrator:false,
              root:true,
              id: '094'
            },

          ],
        },
        {
          icon:'#icon-share',
          index: "sharingPromotion",
          title: "分享推广管理",
          member:true,
          id:'010',
          subs: [
            {
              index: "posterAtlas",
              title: "海报图集",
              memberRoot:true,
            },
            {
              index: "clockData",
              title: "打卡数据",
              memberRoot:true,
            },
          ],
        },
        {
          icon: "#icon-api",
          index: "miniProgram",
          title: "小程序设置",
          id:'011',
          member:true,
          subs: [
            {
              index: "appletConfig",
              title: "基础设置",
              memberRoot:true,
              id:'0110',
            },
            {
              index: "basicSetting",
              title: "小程序配置",
              root:true,
              memberRoot:true,
            },
            {
              index: "mallSetting",
              title: "商城设置",
              memberRoot:true,
              id:'0111',
            },
          ],
        },
      ],
    };
  },

  created() {
    // 控制折叠面板
    bus.$on("collapse", (msg) => {
      this.collapse = msg;
      bus.$emit("collapse-content", msg);
    });
    this.info = JSON.parse(localStorage.getItem('info'));
    this.isMain = this.info.main
    let than =  this
    this.items.map((item,index) => {
      if(this.jurisdiction.includes(item.index)){
        item.member = this.info.roleId ? true : false;
      }
      if(item.index == 'sharingPromotion'){
        item.member = this.isMain ? true : false;
      }
      if(item.subs){
        item.subs.map(subItem=>{
          if(than.memberJurisdiction.includes(subItem.index)){
            subItem.memberRoot = than.info.roleId ? true : false;
          }
        })
      }

    })
  },

  computed: {
    ...mapState(['routerArr']),
    // 主题颜色
    subjectColor() {
      return this.$store.state.subjectColor;
    },
    // 路由配置
    onRoute() {
      return this.$route.path.replace("/", "");
    },
    jurisdiction(){
      let arr = [
        'miniProgram',
        'sharingPromotion',
        'marketingActivities',
        'financialManagement',
        'articleManagement',
        'orderManagement',
      ]
      return arr
    },
    memberJurisdiction(){
      let arr = [
        'totalCustomer',
        'corporateInformation',
        'advertisingManagement'
      ]
      return arr
    },
  },
  watch:{
    routerArr(val){
      try {
        console.log(val,'val')
        let than = this
          this.items.map(item => {
          if(item.id || item.type == 'customer'){//父级
            if(val.includes(item.id)){
              item.member = true
            }else if(item.type == 'customer'){
              item.member = true
            }else{
              item.member = false
            }

            if(item.subs){
              item.subs.map(subItem=>{
                if(subItem.id){//子集

                  if(val.includes(subItem.id)){
                    if(subItem.id == '022'){
                      than.items[2].member = true
                      console.log(than.items,'items')
                    }
                    subItem.memberRoot = true
                    if(subItem.administrator == false){
                      subItem.root = false
                      subItem.administrato = true
                    }
                  }else{
                    subItem.memberRoot = false
                  }
                }

              })
            }
          }


        })
      } catch (error) {
        console.log(error)
      }
      let than = this

    }
  },
  methods:{
    filterRouter(rootId){
      let arr = this.$store.state.routerArr
      let info = JSON.parse(localStorage.getItem('info'));
      if(info.main || info.roleId ){
        return true
      }
      // if()
      let result =  arr.find(item=>{
        return item  == rootId
      })
      return result
    }
  }
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  width: 208px;
  left: 0;
  top: 68px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  display:none;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 208px;
}

.sidebar > ul {
  height: 100%;
}
.sidebar .el-menu{
  border-right:none;
}
.title {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* .el-menu-item {
  border-left: #fff solid 6px;
} */
/* 设置鼠标悬停时el-menu-item的样式 */
/* .el-menu-item:hover {
  border-left: #6190e8 solid 6px !important;
  background-color: #e2eff9 !important;
  color: #6190e8 !important;
} */

/* .el-menu-item:hover i {
  color: #6190e8;
} */

/* 设置选中el-menu-item时的样式 */
.el-menu-item,
.el-submenu {
  /* opacity: 0.8; */
}
.el-menu-item .is-active {
  opacity: 1;
}
.memberRoot{
  display: none;
}
</style>
